/**
 * Define fixed text strings representing the different resource types.
 */
class ResourceTypes {
    static get Image() {
        return 'image';
    }
    static get Video() {
        return 'video';
    }
    static get Font() {
        return 'font';
    }
    static get Logo() {
        return 'logo';
    }
    static get WayfindingIcon() {
        return 'wayfindingIcon';
    }
    static get PDF() {
        return 'pdf';
    }
}

export default ResourceTypes;
