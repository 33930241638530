<!--suppress JSUnfilteredForInLoop -->
<template>
    <v-app
        :class="appClasses"
    >
        <TheScroller></TheScroller>
        <TheLanguageSelector></TheLanguageSelector>
        <TheHelpMessage></TheHelpMessage>
        <TheNotification></TheNotification>
        <TheWelcome></TheWelcome>
        <TheAcceptUpdatedEula></TheAcceptUpdatedEula>
        <TheResourceUploader></TheResourceUploader>
        <TheResourceViewer></TheResourceViewer>
        <TheMapLocationSelector></TheMapLocationSelector>
        <TheFolderStructureSelector></TheFolderStructureSelector>
        <TheClipBoardCopier></TheClipBoardCopier>
        <HelpGetSupport></HelpGetSupport>
        <HelpGetSiteInfo></HelpGetSiteInfo>
        <ThePrompt></ThePrompt>
        <TheConfirm></TheConfirm>
        <TheJobHandler></TheJobHandler>

        <template v-if="($store.state.context.mode === 'loading' || isAuthenticated)">
            <TheAppBar></TheAppBar>
            <div v-if="isAuthenticatedMirage">
                <TheMainDrawer v-if="$store.state.context.mode !== 'loading'" v-model="drawer"></TheMainDrawer>
                <TheMainView></TheMainView>
            </div>
            <div v-else-if="$store.state.context.mode === 'error'">
                <TheInvalidMirageSession
                    :text="this.$keycloak.tokenParsed.domain === 'ppds' ? $t('auth.errorMirageSessionPPDS') : $t('auth.errorMirageSession')"
                    :contextMode="$store.state.context.mode"
                ></TheInvalidMirageSession>
            </div>
            <div v-else-if="$store.state.context.mode !== 'loading'">
                <TheInvalidMirageSession
                        :text="this.$keycloak.tokenParsed.domain === 'ppds' ? $t('auth.invalidMirageSessionPPDS') : $t('auth.invalidMirageSession')"
                    :contextMode="$store.state.context.mode"
                ></TheInvalidMirageSession>
            </div>
        </template>
        <template v-else>
            <TheInvalidMirageSession
                :text="$t('auth.unknownMirageSession')"
                :contextMode="$store.state.context.mode"
            ></TheInvalidMirageSession>
        </template>
    </v-app>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import Vue from 'vue';
import TheMainDrawer from '@/components/core/TheMainDrawer.vue';
import TheMainView from '@/components/core/TheMainView.vue';
import TheNotification from '@/components/core/TheNotification.vue';
import TheScroller from '@/components/core/TheScroller.vue';
import ThePrompt from '@/components/core/ThePrompt.vue';
import TheConfirm from '@/components/core/TheConfirm.vue';
import TheSplashScreen from '@/components/core/TheSplashScreen.vue';
import TheResourceUploader from "@/components/content/resource/TheResourceUploader.vue";
import TheResourceViewer from "@/components/content/resource/TheResourceViewer.vue";
import routeHandlerMixin from "@/mixins/app/theRouteHandlerMixin.js";
import appStateMixin from "@/mixins/app/theAppStateMixin.js";
import BaseFloatingButton from "@/components/base/BaseFloatingButton.vue";
import thePushChannelMixin from "@/mixins/app/thePushChannelMixin.js";
import theResourceUpdateHandlerMixin from "@/mixins/app/theResourceUpdateHandlerMixin.js";
import TheLanguageSelector from "./components/core/TheLanguageSelector.vue";
import TheHelpMessage from "./components/core/TheHelpMessage.vue";
import TheFolderStructureSelector from "./components/folderstructure/TheFolderStructureSelector.vue";
import TheMapLocationSelector from "./components/core/TheMapLocationSelector.vue";
import TheAppBar from "./components/core/TheAppBar.vue";
import TheClipBoardCopier from "./components/core/TheClipBoardCopier.vue";
import TheWelcome from "./components/core/TheWelcome.vue";
import TheAcceptUpdatedEula from "./components/core/TheAcceptUpdatedEula.vue";
import HelpGetSupport from "./components/help/HelpGetSupport.vue";
import HelpGetSiteInfo from "./components/help/HelpGetSiteInfo.vue";
import TheInvalidMirageSession from "./components/core/TheInvalidMirageSession.vue";
import TheJobHandler from "@/components/core/TheJobHandler.vue";

export default {
        name: 'App',
        mixins: [
            routeHandlerMixin,
            appStateMixin,
            theResourceUpdateHandlerMixin,
            thePushChannelMixin
        ],
        components: {
            TheJobHandler,
            HelpGetSupport,
            HelpGetSiteInfo,
            TheWelcome,
            TheClipBoardCopier,
            TheAppBar,
            TheMapLocationSelector,
            TheFolderStructureSelector,
            TheHelpMessage,
            TheLanguageSelector,
            BaseFloatingButton,
            TheResourceViewer,
            TheResourceUploader,
            TheMainDrawer,
            TheMainView,
            ThePrompt,
            TheConfirm,
            TheNotification,
            TheScroller,
            TheSplashScreen,
            TheAcceptUpdatedEula,
            TheInvalidMirageSession
        },
        metaInfo() {
            return {
                title: this.navPath
            }
        },
        data() {
            return {
                floatingHints: [],
                displayingFloatingHint: false,
                didAutoSelection: false,
                screenPreview: null,
                acctInit: false,
                errorCnt: 0
            }
        },
        computed: {
            ...mapState({
                selectedContext: state => state.context.selectedContext,
                contextMode: state => state.context.mode,
                deployments: state => state.deployments.items,
                themes: state => state.themes.items,
                provisioning: state => state.context.provisioning
            }),
            ...mapGetters({
                deploymentsLoaded: 'deployments/isLoaded',
                themesLoaded: 'themes/isLoaded',
                sharesLoaded: 'sharingContexts/isLoaded',
                contentProvidersLoaded: 'contentProviderContexts/isLoaded',
                appPrivilegeCheck: 'context/privilegeCheckCurrentContext'
            }),
            isAuthenticated() {
                return this.$keycloak.authenticated;
            },
            isAuthenticatedMirage() {
                return this.$store.state.auth.isAuthenticated && this.$store.state.context.mode !== 'error';
            },
            navPath() {
                let heading = "Portal ";

                if (this.$store.state.context.mode === 'site' || this.$store.state.context.mode === 'theme') {
                    heading = this.selectedContext.name;
                }
                try {
                    if (this.$route.meta.group && this.$route.meta.group.nameKey && this.$t('router.' + this.$route.meta.group.nameKey)) {
                        heading += '- ' + this.$t('router.' + this.$route.meta.group.nameKey);
                    }
                } catch(e) {
                    console.log(e);
                }
                if(this.$route.name && this.$t('router.' + this.$route.name)) {
                    try {
                        heading += '- ' + this.$t('router.' + this.$route.name);
                    } catch(e) {
                        console.log(e);
                    }
                }
                return heading;
            }
        },
        watch: {
            '$store.state.userAccount.initialized'(val) {
                this.acctInit = val;
            },
            '$store.state.pushChannel.registered'(val) {
                if(val === true) {
                    if(this.$validator.isNotEmpty(this.selectedContext.id)) {
                        console.log("Autoload on PUSH connect");
                        this.autoLoadStores();
                    }
                }
            },
            async '$store.state.deployments.items'(data) {
                if(this.themesLoaded && this.sharesLoaded && this.contentProvidersLoaded) {
                    await this.onContextsLoaded();
                }
            },
            async '$store.state.themes.items'(data) {
                if(this.deploymentsLoaded && this.sharesLoaded && this.contentProvidersLoaded) {
                    await this.onContextsLoaded();
                }
            },
            async '$store.state.sharingContexts.items'(data) {
                if(this.deploymentsLoaded && this.themesLoaded && this.contentProvidersLoaded) {
                    await this.onContextsLoaded();
                }
            },
            async '$store.state.contentProviderContexts.items'(data) {
                if(this.deploymentsLoaded && this.themesLoaded && this.sharesLoaded) {
                    await this.onContextsLoaded();
                }
            },
            async '$store.state.context.mode'(mode, oldMode) {
                if(oldMode !== 'loading' && mode !== oldMode) {
                    const query = this.$router.history.current.query;
                    if (oldMode === 'login' && this.$router.history.current.path === "/screens/tv-screens" && query.clientToken !== undefined && query.id !== undefined) {
                        return;
                    }
                    await this.$router.replace('/').catch(e => {});
                    if(oldMode === 'home') {
                        this.setSearchFilter("");
                        this.setContextFilters([]);
                        this.$store.state.dashboard.summaryContext = null;
                    }
                }
            },
            async '$store.state.context.selectedContext'(context) {
                await this.$store.dispatch('pushChannel/subscribeContextEvents', context.hasOwnProperty('id') ? context.id : null);
                if (this.$routeHandler.ready && !this.$routeHandler.routeAuthCheck(this.$router.history.current)) {
                    await this.$router.replace('/');
                }
                this.resetStores();

                this.autoLoadStores();
                this.$helpMessage.show('mobileDrawerButton', this.$t('help.mobileNavigationTip'), 'mobile', {
                    id: 'mobileDrawerBtn', icon: 'arrow_downward', pos: {top: '-45px'}
                }, false);

                this.showEula();
            },
            floatingHints(newHints, oldHints) {
                if (!this.displayingFloatingHint && newHints.length >= oldHints.length && newHints.length > 0) {
                    const hint = newHints.pop();
                    hint.show();
                    this.displayingFloatingHint = true;
                }
            },
            displayingFloatingHint(displaying) {
                if (!displaying && this.floatingHints.length > 0) {
                    const hint = this.floatingHints.pop();
                    hint.show();
                    this.displayingFloatingHint = true;
                }
            }
        },
        async created() {
            Vue.prototype.$app = this;

            const favicon = document.getElementById("favicon");
            switch (this.getPortalTheme()) {
                case this.$app.portalThemes.THEME_PPDS:
                    favicon.href = "/img/icons/mirage_portal_icon_wave.png";
                    document.title = "Wave Creator";
                    break;
                case this.$app.portalThemes.THEME_UNIGUEST:
                case this.$app.portalThemes.THEME_DEFAULT: {
                    favicon.href = "/img/icons/mirage_portal_icon_uniguest.png";
                    const domain = this.$keycloak.tokenParsed.domain.toString() || "otrum";
                    if (domain === "system") {
                        document.title = "SYSTEM - Hub";
                    } else {
                        document.title = "Hub";
                    }
                }
                    break;
                default: {
                    favicon.href = "/img/icons/mirage_portal_icon_uniguest.png";
                    const domain = this.$keycloak.tokenParsed.domain.toString() || "otrum";
                    if (domain === "system") {
                        document.title = "SYSTEM - Hub";
                    } else {
                        document.title = "Hub";
                    }
                }
                    break;
            }

            const response = await this.$store.dispatch('auth/validateLogin');
            if (response !== true) {
                this.$store.dispatch('context/setLogin');
            }

            const errorHandler = (e) => {
                let errMsg;
                if(e.hasOwnProperty('lineno')) {
                    errMsg = e["filename"] + " line " + e["lineno"] + " : " + e["message"];
                } else {
                    errMsg = e["message"] + '\n' + e['stack'];
                }
                console.error("Error occured(" + this.errorCnt + "): " + errMsg);
                console.error(e);
                if(this.errorCnt < 30) {
                    try {
                        this.$apis.logEntries.post({level: "error", message: errMsg});
                    } catch (e) {
                        console.error("Could not log message to server: " + e);
                    }
                } else {
                    console.log("Not logging error");
                }
                this.errorCnt++;
                return false;
            };
            window.addEventListener("error", errorHandler);
            Vue.config.errorHandler = errorHandler;
        },
        methods: {
            ...mapActions({
                setContextFilters: 'dashboard/setContextFilters',
                setSearchFilter: 'dashboard/setSearchFilter',
            }),
            async selectApplicationContext(context) {
                await this.$store.dispatch('context/selectContext', context);
            },
            showEula() {
                if (this.acctInit === true && !this.$app.portalThemeActive(this.$app.portalThemes.THEME_PPDS)) {
                    let acctSettings = this.$store.state.userAccount.account.settings;
                    let eulaVersion = parseInt(process.env.VUE_APP_EULA_VERSION, 10);

                    if (acctSettings.welcomeDisplayed === false ||
                            location.search.indexOf('welcome') !== -1) {
                        this.$welcome.show();

                    } else if (eulaVersion && acctSettings.acceptedEulaVersion !== eulaVersion) {
                        this.$acceptUpdatedEula.show();
                    }
                }
            },
            featureCheck(feature) {
                let auth = false;

                this.$store.state.context.provisioning.forEach(provision => {
                    if (provision.feature === feature) {
                        auth = true;
                    }
                });
                return auth;
            },
            accessCheck(feature, resource, access = 'read') {
                return this.appPrivilegeCheck(feature, resource, access);
            },
            getLicenseConfiguration(feature, configurationName) {
                if (Array.isArray(this.provisioning)) {
                    const item = Array.findFirstByField(this.provisioning, 'feature', feature);
                    if(item) {
                        if (Array.isArray(item.configurationProvisions)) {
                            const resourceProvision = Array.findFirstByField(item.configurationProvisions, 'configurationName', configurationName);
                            if(resourceProvision) {
                                return resourceProvision.values;
                            }
                        }
                    }
                }
                return [];
            },
            getLicenseLimit(feature, resource, limit) {
                /* If limit is set we check the system limits for a given resource */
                let res = 0;
                if (Array.isArray(this.provisioning)) {
                    const item = Array.findFirstByField(this.provisioning, 'feature', feature);
                    if(item) {
                        if (Array.isArray(item.resourceProvisions)) {
                            const resourceProvision = Array.findFirstByField(item.resourceProvisions, 'resource', resource);
                            if(resourceProvision && Array.isArray(resourceProvision.limits)) {
                                const limitEntry = Array.findFirstByField(resourceProvision.limits, 'limit', limit);
                                if (limitEntry) {
                                    res += limitEntry.value;
                                }
                            }
                        }
                    }
                }
                return res;
            },
            getLicenseLimitStrings(feature, resource, limit) {
                let res = [];
                if (Array.isArray(this.provisioning)) {
                    const item = Array.findFirstByField(this.provisioning, 'feature', feature);
                    if(item) {
                        if (Array.isArray(item.resourceProvisions)) {
                            const resourceProvision = Array.findFirstByField(item.resourceProvisions, 'resource', resource);
                            if(resourceProvision && Array.isArray(resourceProvision.limits)) {
                                const limitEntry = Array.findFirstByField(resourceProvision.limits, 'limit', limit);
                                if(limitEntry && Array.isArray(limitEntry.stringValues)) {
                                    res = res.concat(limitEntry.stringValues);
                                }
                            }
                        }
                    }
                }
                return res;
            },
            licenseLimitCheck(feature, resource, limit, minimum) {
                /* If limit is set we check the system limits for a given resource */
                return (this.getLicenseLimit(feature, resource, limit) >= parseInt(minimum));
            },
            copyToClipboard(toCopy) {
                const dummy = document.createElement("input");
                document.body.appendChild(dummy);
                dummy.setAttribute('value', toCopy);
                dummy.select();
                document.execCommand("copy");
                document.body.removeChild(dummy);

                this.$notification.show('info', this.$t('generic.copiedToClipboard'));
            },
            openNewTab(url) {
                window.open(url, '_blank');
            },
            async onContextsLoaded() {
                const query = this.$router.history.current.query;
                if (query.auth !== undefined && query.auth.length > 0) {
                    this.didAutoSelection = true;
                    await this.$store.dispatch('context/autoSelectFirstContext');
                } else if (!this.didAutoSelection) {
                    this.didAutoSelection = true;
                    await this.$store.dispatch('context/autoSelectContext');
                }
                if ((this.deployments.length + this.themes.length) > 1) {
                    await this.$store.dispatch('context/enableContextSwitching');
                }
            }
        }
    }
</script>

<style lang="scss">

    @font-face {
        font-family: "ProximaVara";
        src: url("../public/fonts/ProximaVara.woff2");
    }

    .v-application {
        background-color: var(--v-background-base) !important;
    }

    .uniguest-theme {
        font-family: ProximaVara;

        .v-btn:hover:not(.base-dialog-btn-cancel):not(.dashboard-toggle-btn):not(.v-btn--icon):not(.folder-tree *):not(.error--text):not(.v-btn--rounded) {
            background-color: var(--v-accent-base) !important;
            transition: background-color .28s;
        }

        .v-btn:hover:is(.error--text):not(.base-dialog-btn-cancel):not(.dashboard-toggle-btn):not(.v-btn--icon):not(.folder-tree *):not(.v-btn--rounded) {
            background-color: var(--v-error-darken4) !important;
            transition: background-color .28s;
        }

        .v-btn:hover:is(.error):not(.base-dialog-btn-cancel):not(.dashboard-toggle-btn):not(.v-btn--icon):not(.folder-tree *):not(.v-btn--rounded) {
            background-color: var(--v-error-darken4) !important;
            transition: background-color .28s;
        }

        .v-btn:not(.v-btn--icon):not(.dashboard-toggle-btn):not(.v-btn--disabled):not(.base-dialog-btn-cancel):not(.v-btn--rounded) {
            color: white !important;
            background-color: var(--v-primary-base) !important;
        }

        .base-dialog-btn-cancel {
            box-shadow: none !important;
            border: 1px solid #BFBFBF !important;
        }
    }

    .application {
        background-color: var(--v-background-base) !important;

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-text-fill-color: inherit !important;
            transition: background-color 5000s ease-in-out 0s;
            -webkit-box-shadow: 0 0 0 30px var(--v-background-base) inset !important;
        }
    }

    .theme--dark {
        .v-data-table,
        .v-card,
        .v-list {
            background-color: var(--v-background-base) !important;
        }
        .v-expansion-panel {
            background-color: #4a4a4a !important;
        }
        .apexcharts-menu {
            background: #333 !important;
        }
        .apexcharts-tooltip {
            background: #333 !important;
        }
        .apexcharts-theme-light .apexcharts-menu-item:hover {
            background: #111 !important;
        }
        .apexcharts-tooltip-title {
            background: #333 !important;
        }
    }

    body {
        background: var(--v-background-base) !important;
    }
</style>
