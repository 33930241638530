import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import apis from '@/lib/api/index';
import Validator from "@/lib/helpers/Validator";

const thisModule = 'screens';

const module = new BaseCrudModule(apis[thisModule], '');

module.addGetters({
    withLabelAndRoom: (state, getters, rootState, rootGetters) => {
        const clientsIdMap = rootGetters['clients/idMapWithLocation'];
        const schedulesIdMap = rootGetters['configSchedules/idMap'];
        const screens = module.cloneItems();

        screens.forEach(item => {
            const client = Map.retrieve(clientsIdMap, item.clientId);
            const schedule = Map.retrieve(schedulesIdMap, item.currentScheduleId);
            item.locationTitle = '';
            if (client) {
                item.name = Validator.isEmpty(client.name) ? client.id : client.name;
                item.locationTitle = client.locationTitle;
            } else {
                item.name = item.clientId;
                console.log("Client not found for " + item.clientId);
            }
            if (schedule) {
                item.currentScheduleName = schedule.name;
            } else {
                item.currentScheduleName = '-';
            }
        });
        return screens;
    },
    mapByCurrentSchedule: (state, getters) => {
        const screens = module.cloneItems();
        const map = {};

        screens.forEach(screen => {
            if(screen.currentScheduleId !== null) {
                if (!map.hasOwnProperty(screen.currentScheduleId)) {
                    map[screen.currentScheduleId] = [];
                }
                map[screen.currentScheduleId].push(screen);
            }
        });
        return map;
    },
    byScheduleRuleSet: (state, getters) => (scheduleRuleSetId) => {
        const items = getters.withLabelAndRoom;
        return items.filter(item => {
            return (item.scheduleRuleSetId === scheduleRuleSetId);
        });
    },
    byScheduleRuleSetSorted: (state, getters) => (scheduleRuleSetId) => {
        const items = getters.byScheduleRuleSet(scheduleRuleSetId);
        Array.sort(items, "name");
        return items;
    }
});

export default module;
