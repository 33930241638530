import BaseListHeaderModule from "@/store/modules/base/BaseListHeaderModule";

const module = new BaseListHeaderModule();

module.setListId('tvHeaders');

module.setListHeaders({
    'name': {
        value: 'name',
        text: 'Name',
        initialEnabled: true,
        convertToString: (item) => { return '=\"' + item['name'] + '\"'; },
    },
    'ip': {
        value: 'ip',
        text: 'IP',
        initialEnabled: true
    },
    'mac': {
        value: 'mac',
        text: 'MAC',
        initialEnabled: true
    },
    'locationTitle': {
        value: 'locationTitle',
        text: 'Location',
        initialEnabled: false
    },
    'deviceType': {
        value: 'deviceType',
        text: 'Type',
        initialEnabled: true
    },
    'appVersion': {
        value: 'status.appVersion',
        text: 'App',
        initialEnabled: true,
        convertToString: (item) => { return item['status']['appVersion']; }
    },
    'firmwareVersion': {
        value: 'status.firmwareVersion',
        text: 'Firmware',
        initialEnabled: true,
        convertToString: (item) => { return item['status']['firmwareVersion']; }
    },
    'firmwareVersionFull': {
        value: 'status.firmwareVersion',
        text: 'Firmware',
        initialEnabled: false,
        convertToString: (item) => { return item['status']['firmwareVersion']; }
    },
    'online': {
        type: 'icon',
        text: 'Online',
        iconColor: (item) => {
            return item['online'] === true ? 'success' : 'error'
        },
        iconField: (item) => {
            return item['online'] === true ? 'check_box' : 'warning';
        },
        value: 'online',
        hideValue: true,
        initialEnabled: true
    },
    'power': {
        type: 'icon',
        text: 'State',
        iconColor: (item) => {
            return item['poweredOn'] === true ? 'success' : '#cccccc'
        },
        iconField: (item) => {
            return item['poweredOn'] === true ? 'mdi-television' : (item['online'] === true ? 'mdi-television-off' : '');
        },
        value: 'poweredOn',
        hideValue: true,
        initialEnabled: true,
        convertToString: (item) => { return (item['poweredOn'] ? "ON" : "STANDBY"); }
    },
    'occupied': {
        value: 'occupied',
        text: 'Occupied',
        initialEnabled: true
    },
    'id': {
        value: 'id',
        text: 'ID',
        initialEnabled: true
    },
    'extendedId': {
        value: 'extendedId',
        text: 'Extended ID',
        initialEnabled: false
    },
    'lastReboot': {
        value: 'id',
        text: 'Last reboot',
        initialEnabled: true
    },
});

export default module;
