import BaseListHeaderModule from "@/store/modules/base/BaseListHeaderModule";

const module = new BaseListHeaderModule();

module.setListId('signageHeaders');

module.setListHeaders({
    'name': {
        value: 'name',
        text: 'Name',
        initialEnabled: true
    },
    'locationTitle': {
        value: 'locationTitle',
        text: 'Location',
        initialEnabled: false
    },
    'rotation': {
        value: 'rotation',
        text: 'Rotation',
        initialEnabled: true
    },
    'ip': {
        value: 'ip',
        text: 'IP',
        initialEnabled: true
    },
    'mac': {
        value: 'mac',
        text: 'MAC',
        initialEnabled: true
    },
    'deviceType': {
        value: 'deviceType',
        text: 'Device Type',
        initialEnabled: true
    },
    'appVersion': {
        value: 'status.appVersion',
        text: 'App version',
        initialEnabled: true
    },
    'firmwareVersion': {
        value: 'status.firmwareVersion',
        text: 'Firmware',
        initialEnabled: true
    },
    'firmwareVersionFull': {
        value: 'status.firmwareVersion',
        text: 'Firmware',
        initialEnabled: false
    },
    'online': {
        type: 'icon',
        text: 'Online',
        iconColor: (item) => {
            return item['online'] === true ? 'success' : 'error'
        },
        iconField: (item) => {
            return item['online'] === true ? 'check_box' : 'warning';
        },
        value: 'online',
        hideValue: true,
        initialEnabled: true
    },
    'power': {
        type: 'icon',
        text: 'State',
        iconColor: (item) => {
            return item['poweredOn'] === true ? 'success' : '#cccccc'
        },
        iconField: (item) => {
            return item['poweredOn'] === true ? 'mdi-television' : (item['online'] === true ? 'mdi-television-off' : '');
        },
        value: 'poweredOn',
        hideValue: true,
        initialEnabled: true
    },
    'id': {
        value: 'id',
        text: 'ID',
        initialEnabled: true
    },
    'livePreview': {
        value: 'livePreview',
        text: 'Live Preview',
        initialEnabled: true
    },
});

export default module;
